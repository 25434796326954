.uploadButton {
  outline: 3px dashed #a9a9a9;
  padding: 8px 8px;
  border-radius: 3px;
  cursor: pointer;
}

.doneButton {
  outline: 3px solid #3ec706;
  padding: 8px 8px;
  border-radius: 3px;
  color: #3ec706;
}
